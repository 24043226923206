<template>
<div class="h100">
    
    <video-player class="video-player-box"
        ref="videoPlayerBack"
        :options="playerOptions"
        :playsinline="true"
        @ended="onPlayerEnded($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        customEventName="customstatechangedeventname"
        ></video-player>

</div>
    

        
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import { EventBus } from '@/core/event-bus.js';
import { GAME_STEP, GAME_EVENT } from '@/core/gameConfig'

export default {
    data() {
        return {
            playerOptions: {
				// videojs options
				muted: true,
				language: 'fr',
				controls: false,
				//playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [{
					type: "video/mp4",
					src: "zoomvisioinverse.mp4"
				}],
				//poster: "/static/images/author.jpg",
			},
        }
        
    },
    components : {
		videoPlayer,
    },
    computed: {
      player() {
        return this.$refs.videoPlayerBack.player
      },
    },
    mounted() {
        EventBus.$on('startTransitionBack', () => {
            this.player.play()
		});
    },
    methods: {        
        onPlayerEnded() {            
            
        },
        onPlayerTimeupdate(){
            if(this.player.duration() - this.player.currentTime() < 0.5) {
                this.player.pause()
                this.$store.commit("setGameStep", GAME_STEP.OUTRO)
                EventBus.$emit(GAME_EVENT.SHOWDIRECTIVE, 4);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.modal{
    display: block;
}
.video-player-box {
    position:absolute;
    top: 0;
    left: 0;
    height: 638px;

    .video-js {
        height: 638px;
    }

    video {
        height: 638px;
    }
}
</style>